(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"), require("moment"), require("ReactDOM"), require("setimmediate"), require("Highcharts"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "_", "moment", "ReactDOM", "setimmediate", "Highcharts"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("React"), require("_"), require("moment"), require("ReactDOM"), require("setimmediate"), require("Highcharts")) : factory(root["React"], root["_"], root["moment"], root["ReactDOM"], root["setimmediate"], root["Highcharts"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__39__, __WEBPACK_EXTERNAL_MODULE__827__, __WEBPACK_EXTERNAL_MODULE__944__) {
return 