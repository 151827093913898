import {BaseAPI} from "@vidazoo/ui-framework";

class SitesAPI extends BaseAPI {
    public getAll(page = 1, pageSize = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/site_list/",
            params: {page, pageSize, filter, fields, sort}
        });
    }

    public getAllAnyAdminAndVidazoo(page = 1, pageSize = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/site_list/all",
            params: {page, pageSize, filter, fields, sort}
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/site_list/${id}/`
        });
    }

    public create(siteList: any, selectedPublisher: string) {
        return this.request({
            method: "post",
            url: "/api/site_list/create_multi/",
            data: {siteList, selectedPublisher}
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/site_list/update_site/${id}/`,
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/site_list/${id}/`
        });
    }

    public scan(data) {
        return this.request({
            method: "patch",
            url: `/api/site_list/scan_ads_txt/`,
            data
        });
    }

    public scanOwnedBy(data) {
        return this.request({
            method: "patch",
            url: `/api/site_list/scan_owned_by/`,
            data
        });
    }

    public getAdsTxt() {
        return this.request({
            method: "get",
            url: `/api/site_list/adstxt/`
        });
    }
}

export default new SitesAPI((window as any).__BASE_API_URL__, true);
